import request from '@/utils/request'
const baseUrl = 'https://kefu.renruikeji.cn'
// const baseUrlTest = 'https://kefutest.renruikeji.cn'
const baseUrlVideo = 'https://newplatform.renruikeji.cn'
const baseUrlWechat = 'https://wechat.renruikeji.cn'
// 获取js sdk 配置信息
export function getJsSdkConfig(data) {
  return request({
    url: '/login/getAgentConfigById',
    method: 'post',
    params: data,
    baseURL: baseUrl
  })
}
// 获取用户信息
export function getUserInfo(data) {
  return request({
    url: '/assistant/getUserTags',
    method: 'post',
    params: data,
    baseURL: baseUrl
  })
}
// 获取话术列表
export function getWordList(data) {
  return request({
    url: '/assistant/getSpeechArtList',
    method: 'post',
    params: data,
    baseURL: baseUrl
  })
}
// 新增话术
export function addWord(data) {
  return request({
    url: '/assistant/saveSpeechArt',
    method: 'post',
    params: data,
    baseURL: baseUrl
  })
}
// 存储日志
export function saveLog(data) {
  return request({
    url: '/assistant/saveAssistantLog',
    method: 'post',
    params: data,
    baseURL: baseUrl
  })
}
// 删除话术
export function delWord(data) {
  return request({
    url: '/assistant/delSpeechArt',
    method: 'get',
    params: data,
    baseURL: baseUrl
  })
}
// 根据code获取用户id
export function getUserId(data) {
  return request({
    url: '/login/getChathelperUserinfo',
    method: 'get',
    params: data,
    baseURL: baseUrl
  })
}
// 添加整理话术
export function addTempWord(data) {
  return request({
    url: '/assistant/saveSortedArt',
    method: 'post',
    params: data,
    baseURL: baseUrl
  })
}
// 获取素材列表
export function getSourceList(data) {
  return request({
    url: '/material/getMediaList',
    method: 'post',
    params: data,
    baseURL: baseUrl
  })
}
// 获取答题列表
export function getVideoList(data) {
  return request({
    url: '/chathelper/videoAnswerList',
    method: 'get',
    params: data,
    baseURL: baseUrlVideo
  })
}
// 获取文件mediaid列表
export function getFileMedia(data) {
  return request({
    url: '/material/getMediaId',
    method: 'post',
    params: data,
    baseURL: baseUrl
  })
}
// 添加分享日志
export function addShareLog(data) {
  return request({
    url: '/material/saveSuffixLog',
    method: 'post',
    params: data,
    baseURL: baseUrl
  })
}
// 获取是否是店长
export function getShopUser(data) {
  return request({
    url: '/login/getTagsByUserId',
    method: 'post',
    params: data,
    baseURL: baseUrlWechat
  })
}
// 获取店长信息
export function getShopUserInfo(data) {
  return request({
    url: '/login/getDepIdByUserid',
    method: 'post',
    params: data,
    baseURL: baseUrl
  })
}
// 获取分类
export function getAllType(data) {
  return request({
    url: '/material/getGzhMaterialTypeList',
    method: 'post',
    params: data,
    baseURL: baseUrl
  })
}

