<template>
  <div class="main">
    <div class="chat">
      <!-- 顶部tap切换开始 -->
      <div class="tap-box">
        <div class="tap-text-box">
          <div class="tap-select-text">聊天助手</div>
          <div class="tap-select-line"></div>
        </div>
        <div @click="toSource" class="tap-text-box">
          <div class="tap-def-text">素材</div>
          <div class="tap-def-line"></div>
        </div>
        <!--<div @click="toAnswer" class="tap-text-box">
          <div class="tap-def-text">答题</div>
          <div class="tap-def-line"></div>
        </div>-->
      </div>
      <!-- 顶部tap切换结束 -->
      <div v-if="entry === 'single_chat_tools'" class="top-tag-box">
        <div class="no-tag" v-if="tagsAll.length<=0">暂无标签</div>
        <ul class="user-info-label-list">
          <li class="user-info-label-text li-padding" :class="label.class" v-for="label in tagsAll" :key="label.name">
            <span >{{label.name}}</span>
          </li>
        </ul>
      </div>
      <div class="search-box">
        <div class="search-box-input">
          <van-search v-model="queryPostData.title" placeholder="请输入关键词" @input="showList"/>
        </div>
        <div @click="addTempWord" class="search-button">搜索</div>
      </div>
      <van-list
              v-model="loading"
              :finished="finished"
              :immediate-check="false"
              finished-text="没有更多信息了"
              @load="onLoad"
      >
        <div>
          <van-radio-group v-model="radio">
            <div  v-for="item in list" :key="item.id">
              <van-radio :name="item.id" icon-size="16px" @click="changeRadio(item,index)">
                <div class="list-box">
                  <div class="list-title-box">
                    <img v-if="item.assType == 1" class="list-title-img" src="../assets/image/person.png">
                    <img v-else class="list-title-img" src="../assets/image/public.png">
                    <div class="list-title" v-html="item.title"></div>
                  </div>
                  <div class="list-content">{{item.content}}</div>
                </div>
              </van-radio>
            </div>
          </van-radio-group>
        </div>
      </van-list>
      <div class="bottom-margin"></div>
    </div>
    <div class="bottom-box" v-show="isOriginHei ||!inputFocus">
      <div @click="sendToUser" :class="buttonClass">发送</div>
      <div @click="toEdit" :class="buttonRightClass">编辑后发送</div>
      <div @click="toAdd" class="bottom-right">{{buttonWord}}</div>
    </div>
    <div class="edit-box">
      <div></div>
    </div>
    <div @click="reloadPage" class="r-button"><van-icon name="replay" /></div>
  </div>
</template>
<script>
import { getUserId, getJsSdkConfig, getUserInfo, getWordList, saveLog, addTempWord, getShopUserInfo } from '@/api/service'
import { handleClipboard } from '../assets/js/clipboard'
import Cookies from 'js-cookie'
import { Toast } from 'vant';
import { Dialog } from 'vant';
const wx = window.wx;
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      userId: '', // 用户id
      nowUserId: '', // 外部联系人id
      nowUserInfo: {}, // 当前用户信息
      configPostData: {
        agentId: 1000069,
        url: ''
      },
      jsSdkConfig: {},
      userPostData: {
        userid: '',
        externalUserid: ''
      },
      tagsAll : [],
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      total:0,
      disableType:false,
      queryPostData: {
        userid: '',
        title: '',
        page: 1,
        size: 10
      },
      radio: 1,
      buttonClass: 'bottom-left1',
      buttonRightClass: 'bottom-right1',
      buttonWord: '新增话术',
      selectItem: {},
      isOriginHei: true,  //显示或者隐藏button
      inputFocus: false,
      documentHeight: document.documentElement.clientHeight,
      isSelf: false,
      entry: '',
      labelData:[{
        select:0,
        type: 0,
        typename:'聊天助手'
      },{
        select:0,
        type: 1,
        typename:'素材'
      }
      ],
      tapType: 1, // 当前选择tap
      isShow:false,
      isShop: false,
      tapArray:[{
        text: 'tap-select-text',
        line: 'tap-select-line',
      },{
        text: 'tap-def-text',
        line: 'tap-def-line',
      }],
    }},
  created() {
    // Cookies.set('userId', 'Wangleiqiang')
    let userId=Cookies.get('userId')
    this.queryPostData.userid = userId
    this.userPostData.userid = userId
    this.userId = userId
    if(!userId){
      this.login()
    }else{
      // 获取jssdk 配置的信息
      this.getConfig('config')
      this.onLoad()
      this.userId=userId
      this.initTap()
    }
  },
  mounted:function(){
    //navigator.userAgent.indexOf用来判断浏览器类型
    let isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1;
    if (isAndroid){//如果是安卓手机的浏览器
      // window.onresize监听页面高度的变化
      window.onresize = () => {
        return (() => {
          if(this.documentHeight>document.documentElement.clientHeight){
            this.isOriginHei = false
          }else{
            this.isOriginHei =true
          }
        })()
      }
    }
  },
  methods: {
    // 用户网页授权登录
    login(){
      let query=this.$route.query;
      if(query.code){
        let postData={}
        postData.code=query.code
        getUserId(postData).then(response => {
          if(response.code===200){
            Cookies.set('userId', response.data)
            this.userId=response.data
            this.queryPostData.userid = this.userId
            this.userPostData.userid = this.userId
            this.onLoad()
            // 获取jssdk 配置的信息
            this.getConfig('config')
          }else{
            Toast.fail('获取userId失败');
            return false
          }
        })
      }else{
        let nowUrl = window.location.href;
        let backurl = encodeURIComponent(nowUrl)
        let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwf93ebffd5d31aa7a&redirect_uri=' + backurl + '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
        window.location.href=url
      }
    },
    // 判断是不是店长
    /*initTap() {
      let that = this
      let postInfo = {}
      postInfo.userId = this.userId
      getShopUser(postInfo).then(response => {
        if(response.data.shopowner === 1){
          that.isShop = true
        }
      })
    },*/
    initTap() {
      // let that = this
      let postInfo = {}
      console.log(this.userId)
      postInfo.userId = this.userId
      getShopUserInfo(postInfo).then(response => {
        Cookies.set('shopUserId', response.data.userid)
        Cookies.set('shopId', response.data.depId[0])
      })
    },
    // 获取sdk  配置
    getConfig() {
      let that = this
      this.configPostData.url = window.location.href;
      getJsSdkConfig(this.configPostData).then(response => {
        that.initAgentConfig(response.data);
      })
    },
    // 推送数据到数据库
    pushData() {
      let postData = {}
      postData.userid = this.userId
      postData.extenalUserid = this.nowUserId
      let sUserAgent = navigator.userAgent.toLowerCase();
      if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
        postData.type = 'app'
      } else {
        postData.type = 'pc'
      }
      postData.title = this.selectItem.title
      let replaceReg = new RegExp('<span class="highlights-text">', 'g')// 匹配关键字正则
      let replaceString = '' // 高亮替换v-html值
      postData.title = postData.title.replace(replaceReg, replaceString) // 开始替换
      let replaceReg2 = new RegExp('</span>', 'g')// 匹配关键字正则
      let replaceString2 = '' // 高亮替换v-html值
      postData.title = postData.title.replace(replaceReg2, replaceString2) // 开始替换
      saveLog(postData).then(response => {
        console.log(response)
      })
    },
    // 获取用户信息
    getUserInfo() {
      getUserInfo(this.userPostData).then(response => {
        let allTags = response.data
        // 判断颜色循环
        let color = 1
        for(let i = 0; i < allTags.length; i++) {
          for (let k = 0; k < allTags[i].tags.length; k++) {
            if(allTags[i].tags[k].tag_name !== '未知'){
              let newTag = {}
              newTag.class = this.getClass(color)
              newTag.name = allTags[i].tags[k].tag_name
              this.tagsAll.push(newTag)
            }
          }
          if (color >= 5) {
            color = 1
          }else{
            color ++
          }
        }
        Toast.clear();
      })
    },
    // 获取标签的class
    getClass(num) {
      switch (num) {
        case 1:
          return 'tag1'
        case 2:
          return 'tag2'
        case 3:
          return 'tag3'
        case 4:
          return 'tag4'
        case 5:
          return 'tag5'
      }
    },
    onLoad() {
      this.getData()
      // 加载状态结束
      this.loading = false;
      this.refreshing = false;
    },
    // 刷新页面
    reloadPage(){
      location.reload();
    },
    getData() {
      if(this.disableType === true){
        return false
      }
      this.disableType = true;
      getWordList(this.queryPostData).then(response => {
        this.disableType=false;
        let nowPage=this.queryPostData.page
        this.queryPostData.page=nowPage+1
        for (let i = 0; i < response.data.list.length; i++) {
          if(this.queryPostData.title !== ''){
            let replaceReg = new RegExp(this.queryPostData.title, 'g')// 匹配关键字正则
            let replaceString = '<span class="highlights-text">' + this.queryPostData.title + '</span>' // 高亮替换v-html值
            response.data.list[i].title = response.data.list[i].title.replace(replaceReg, replaceString) // 开始替换
          }
          this.list.push(response.data.list[i]);
        }
        if(this.list.length>=response.data.total){
          this.finished = true;
        }
      })
    },
    showList() {
      this.finished = false
      this.list = []
      this.queryPostData.page=1
      this.getData();
      // 加载状态结束
      this.loading = false;
      this.refreshing = false;
    },
    // 复制内容
    copy() {
      if (JSON.stringify(this.selectItem) === "{}"){
        Toast('请先选择话术');
        return
      }
      handleClipboard(this.selectItem.content, event, () => {
        Toast('复制成功');
      }, () => {
        Toast('复制失败');
      })
    },
    // 获取进入页面的来源
    getFrom() {
      let that = this
      wx.invoke('getContext', {
      }, function(res){
        console.log(res)
        if(res.err_msg == "getContext:ok"){
          let entry  = res.entry ; //返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools、chat_attachment
          that.entry = entry
          if (entry !== 'single_chat_tools' && entry !== 'group_chat_tools') {
            //错误处理
            Dialog.alert({
              title: '错误',
              message: '请从聊天窗口打开！',
            }).then(() => {
              // on close
              window.location.href="about:blank";
            });
          }else{
            that.getNowUser()
          }
        }else {
          //错误处理
        }
      });
    },
    // 获取外部联系人id
    getNowUser() {
      let that = this
      // 获取外部联系人id
      wx.invoke('getCurExternalContact', {
      },function(res){
        console.log(res)
        if(res.err_msg == "getCurExternalContact:ok"){
          that.nowUserId  = res.userId ; //返回当前外部联系人userId
          that.userPostData.externalUserid = res.userId
          that.getUserInfo()
        }else {
          return
        }
      })
    },
    // 选择话术
    changeRadio(item) {
      if (JSON.stringify(this.selectItem) !== "{}"){
        // 判断是不是点击取消选择
        if(item.id === this.selectItem.id){
          this.selectItem = {}
          this.buttonClass = 'bottom-left1'
          this.buttonRightClass = 'bottom-right1'
          this.buttonWord = '新增话术'
          this.radio = 0
          this.isSelf = false
          return
        }
      }
      // 判断是修改个人 还是用户
      if(item.assType === 2){
        this.selectItem = item
        this.buttonClass = 'bottom-left2'
        this.buttonRightClass = 'bottom-right2'
        this.isSelf = true
        return;
      }
      this.selectItem = item
      this.buttonClass = 'bottom-left2'
      this.buttonRightClass = 'bottom-right2'
      this.buttonWord = '编辑话术'
    },
    // 发送消息到客户聊天窗口
    sendToUser() {
      let that = this
      let message = this.selectItem.content
      if(!message){
        return
      }
      wx.invoke('sendChatMessage', {
        msgtype: "text", //消息类型，必填
        enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
        text: {
          content: message, //文本内容
        },
      },function(res) {
        if (res.err_msg == 'sendChatMessage:ok') {
          //发送成功
          that.pushData()
        }
      })
    },
    // 跳转到编辑页面
    toAdd() {
      if(JSON.stringify(this.selectItem) !== "{}"){
        let replaceReg = new RegExp('<span class="highlights-text">', 'g')// 匹配关键字正则
        let replaceString = '' // 高亮替换v-html值
        this.selectItem.title = this.selectItem.title.replace(replaceReg, replaceString) // 开始替换
        let replaceReg2 = new RegExp('</span>', 'g')// 匹配关键字正则
        let replaceString2 = '' // 高亮替换v-html值
        this.selectItem.title = this.selectItem.title.replace(replaceReg2, replaceString2) // 开始替换
      }
      if(this.isSelf){
        this.$router.push({ path: '/Add', query: {} });
      }else{
        this.$router.push({ path: '/Add', query: this.selectItem });
      }
    },
    // 跳转到编辑并发送
    toEdit() {
      if (JSON.stringify(this.selectItem) === "{}"){
        Toast('请先选择话术');
        return
      }
      let replaceReg = new RegExp('<span class="highlights-text">', 'g')// 匹配关键字正则
      let replaceString = '' // 高亮替换v-html值
      this.selectItem.title = this.selectItem.title.replace(replaceReg, replaceString) // 开始替换
      let replaceReg2 = new RegExp('</span>', 'g')// 匹配关键字正则
      let replaceString2 = '' // 高亮替换v-html值
      this.selectItem.title = this.selectItem.title.replace(replaceReg2, replaceString2) // 开始替换
      this.$router.push({ path: '/Edit', query: this.selectItem });
    },
    // 跳转到素材列表
    toSource(){
      this.$router.push({ path: '/Source', query: {} });
    },
    // 跳转到答题列表
    toAnswer(){
      this.$router.push({ path: '/Answer', query: {} });
    },
    // 添加整理话术
    addTempWord(){
      let postData = {}
      if(this.queryPostData.title.length < 5 ){
        return
      }
      postData.title = this.queryPostData.title
      postData.userid = this.queryPostData.userid
      addTempWord(postData).then(response => {
        console.log(response)
      })
    },
    // 配置jssdk
    initJsSdk(jsSdkConfig) {
      let that = this
      wx.config({
        beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: jsSdkConfig.appId, // 必填，企业微信的corpID
        timestamp: jsSdkConfig.timestamp, // 必填，生成签名的时间戳
        nonceStr: jsSdkConfig.nonceStr, // 必填，生成签名的随机串
        signature: jsSdkConfig.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: ['getCurExternalContact','sendChatMessage','openEnterpriseChat'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      })
      wx.ready(function(){
        console.log('初始化js sdk 成功')
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        that.getConfig('agent_config')
      })
      wx.error(function(res){
        console.log(res)
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      })
    },
    // 初始化 agentConfig
    initAgentConfig(jsSdkConfig) {
      let that = this
      wx.agentConfig({
        corpid: jsSdkConfig.appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: '1000069', // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: jsSdkConfig.timestamp, // 必填，生成签名的时间戳
        nonceStr: jsSdkConfig.nonceStr, // 必填，生成签名的随机串
        signature: jsSdkConfig.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
        jsApiList: ['getCurExternalContact','sendChatMessage','getContext'], //必填，传入需要使用的接口名称
        success: function() {
          // 回调
          console.log('初始化agent 成功')
          that.getFrom()
        },
        fail: function(res) {
          console.log(res)
          if(res.errMsg.indexOf('function not exist') > -1){
            alert('版本过低请升级')
          }
        }
      });
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .main{
    background-color:#ffffff;
  }
  .chat{
    background-color:#ffffff;
    padding: 20px;
    min-height: 100vh;
  }
  .top-title{
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #0A0A0A;
    line-height: 20px;
    padding: 0 0 15px 0;
    border-bottom: 1px dashed #d4d7d7;
  }
  .top-tag-box{
    max-height: 170px;
    overflow-y: scroll;
    margin-top: 10px;
    font-size: 13px;
  }
  .user-info-label-list{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap:wrap;
    position: relative;
  }
  .user-info-label-text{
    white-space: nowrap;
    width: auto;
    padding: 3px 8px;
    margin: 0 10px 0 0 ;
  }
  .li-padding{
    margin: 0 10px 10px 0 ;
  }
  .tag1{
    background: rgba(37, 134, 255, 0.05);
    border-radius: 2px;
    border: 1px solid #2586FF;
    color: #2586FF;
  }
  .tag2{
    background: rgba(98, 54, 255, 0.05);
    border-radius: 2px;
    border: 1px solid #6236FF;
    color: #6236FF;
  }
  .tag3{
    background: rgba(7, 193, 96, 0.05);
    border-radius: 2px;
    border: 1px solid #07C160;
    color: #07C160;
  }
  .tag4{
    background: rgba(255, 67, 54, 0.05);
    border-radius: 2px;
    border: 1px solid #FF4336;
    color: #FF4336;
  }
  .tag5{
    background: rgba(255, 105, 5, 0.05);
    border-radius: 2px;
    border: 1px solid #FF6905;
    color: #FF6905;
  }
  .search-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px 0 10px 0;
  }
  .search-box-input{
    width: 100%;
    margin-right: 15px;
  }
  .van-search {
    padding: 0 !important;
  }
  .list-box{
    margin: 10px 0 0 15px;
    width: 75vw;
    overflow: hidden;
    text-overflow:ellipsis;
    border-bottom: 1px solid #E5E5E5;
  }
  .list-title{
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    line-height: 20px;
    display:-webkit-box;/**对象作为伸缩盒子模型展示**/
    -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
    -webkit-line-clamp:1;/**显示的行数**/
    overflow:hidden;/**隐藏超出的内容**/
  }
  .list-title-box{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .list-title-img{
    width: 20px;
    height: 20px;
    vertical-align: center;
    margin-right: 5px;
  }
  .list-content{
    margin: 5px 0 10px 0;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
    display:-webkit-box;/**对象作为伸缩盒子模型展示**/
    -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
    -webkit-line-clamp:2;/**显示的行数**/
    overflow:hidden;/**隐藏超出的内容**/
  }
  .bottom-box{
    position: fixed;
    bottom: 0;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
    background-color: #ffffff;
  }
  .bottom-button-w{
    width: 108px;
  }
  .bottom-left1{
    width: 108px;
    height: 36px;
    background: #a5eec8;
    box-shadow: 3px 3px 8px 0px rgba(48, 51, 57, 0.2);
    border-radius: 2px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
  }
  .bottom-left2{
    width: 108px;
    height: 36px;
    background: linear-gradient(136deg, #20DE7B 0%, #07C160 100%);
    box-shadow: 3px 3px 8px 0px rgba(48, 51, 57, 0.2);
    border-radius: 2px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
  }
  .bottom-right{
    width: 108px;
    height: 36px;
    line-height: 36px;
    background: linear-gradient(136deg, #5DA3FA 0%, #2586FF 100%);
    box-shadow: 3px 3px 8px 0px rgba(48, 51, 57, 0.2);
    border-radius: 2px;
    text-align: center;
    color: #ffffff;
  }
  .bottom-right2{
    width: 108px;
    height: 36px;
    line-height: 36px;
    background: linear-gradient(136deg, #5DA3FA 0%, #2586FF 100%);
    box-shadow: 3px 3px 8px 0px rgba(48, 51, 57, 0.2);
    border-radius: 2px;
    text-align: center;
    color: #ffffff;
  }
  .bottom-right1{
    width: 108px;
    height: 36px;
    line-height: 36px;
    background: linear-gradient(136deg, #86b9f8 0%, #7eb3f5 100%);
    box-shadow: 3px 3px 8px 0px rgba(48, 51, 57, 0.2);
    border-radius: 2px;
    text-align: center;
    color: #ffffff;
  }
  .bottom-margin{
    height: 100px;
  }
  .highlights-text{
    background: #D5E6FC;
  }
  .no-tag{
    display: flex;
    justify-content: center;
    padding: 10px 0 10px 0;
    color: #FF4336;
  }
  .search-button{
    background-color: #2586FF;
    color: #ffffff;
    border-radius: 5px;
    width: 70px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-size: 15px;
  }
  /* tap切换开始 */
  .tap-box{
    display: flex;
    justify-content: space-around;
    margin-bottom:20px;
  }
  .tap-text-box{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .tap-select-text{
    font-size: 16px;
    color: #007AFF;
    font-weight: bold;
  }
  .tap-select-line{
    background-color: #007AFF;
    height: 3px;
    margin-top: 10px;
  }
  .tap-def-text{
    font-size: 14px;
    color: #000000;
    font-weight: bold;
  }
  .tap-def-line{
    background-color: #ffffff;
    height: 3px;
    margin-top: 10px;
  }
  /* tap切换结束 */
  .r-button{
    position: fixed;
    color: #F8F8F8;
    top: 30vh;
    right: 20px;
    background-color: rgba(10, 10, 10, 0.2);
    padding: 10px;
    border-radius: 10px;
  }
</style>
